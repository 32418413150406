import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    at: '',
    rt: '',
  },
  getters: {
    getAt (state) {
        return state.at
    },
    getRt (state) {
        return state.rt
    }
  },
  mutations: {
    setAt (state, at) {
        state.at = at
    },
    setRt (state, rt) {
        state.rt = rt
    }
  },
  actions: {
    setAt ({ commit }, at) {
        commit('setAt', at)
    },
    setRt ({ commit }, rt) {
        commit('setRt', rt)
    }
  },
  modules: {
  }
})
